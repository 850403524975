import React from 'react'
// import { Button } from 'antd'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div>
        <div>
          Copyright &copy; 2023 巴金森手記 v1.06 Powered by Funcode | Designed by DesignFuture
        </div>
      </div>
    </div>
  </div>
)

export default Footer
